.sidebar {
    position: fixed;
    top: 67px;
    left: 0;
    height: auto;
    max-width: 218px;
    padding: 20px 16px;
    display: block;
    border-right: 1px solid #E5E7EB;
    height: calc(100% - 67px);
}

ul.sidebar-menu-items {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    margin-top: 16px;
    overflow-y: auto;
    height: calc(100vh - 163px);
}

ul.sidebar-menu-items li {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 4px;
    background: var(--Generic-White, #FFF);
}

ul.sidebar-menu-items li a {
    gap: 8px;
    display: flex;
    align-items: center;
    color: var(--Neutral-700, #374151);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
}

ul.sidebar-menu-items li:hover {
    background: #E6F8F2;
    transition: all 0.5s;
}

.sidebar a.support {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 4px;
    background: var(--Generic-White, #FFF);
    gap: 4px;
    color: var(--Neutral-700, #374151);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
}

ul.sidebar-menu-items li:hover a {
    color: var(--Primary-700, #567663);
    /* 114.286% */
}

/* Basic icon styling */
.sidebar-icon {
    width: 24px;
    /* Adjust size as needed */
    height: 24px;
    /* Adjust size as needed */
    stroke: black;
    /* Default border color */
    transition: stroke 0.3s ease;
    /* Smooth transition for border color */
}

/* Hover state for the links */
.sidebar-menu-items li:hover .sidebar-icon,
.support:hover .sidebar-icon {
    stroke: #71917F;
    /* Hover border color */
}

.sidebar-menu-items li:hover path {
    stroke: #567663;
}

.sidebar-menu-items li.active {
    background: #E6F8F2;
    transition: all 0.5s;
}

.sidebar-menu-items li.active a {
    color: #567663;
}

.sidebar-menu-items li.active path {
    stroke: #567663;
}