.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media only screen and (min-width: 1430px) {
  .container {
    max-width: 1290px;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}

@media only screen and (max-width: 1800px) {}


@media only screen and (max-width: 1599px) {
  .add-bg {
    gap: 11px !important;
  }
}

@media only screen and (max-width: 1429px) {}

@media only screen and (max-width: 1199px) {
  .main-content {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media only screen and (max-width: 991px) {
  .table-info h3 {
    font-size: 26px !important;
  }

  .modaltableinnerinfo p {
    font-size: 14px !important;
  }

  .modalpeople h5 {
    font-size: 14px !important;
  }

  button.accordion-button {
    font-size: 18px !important;
  }

  .order-name h5 {
    font-size: 14px !important;
  }

  .order-prize p {
    font-size: 14px !important;
  }

  .countitems img {
    width: 100% !important;
    height: 100% !important;
  }

  .countitems p {
    font-size: 14px !important;
  }

  .table {
    gap: 8px !important;
  }

  .table-text h2 {
    font-size: 18px !important;
    margin-bottom: 0 !important;
  }

  .table-bg {
    padding: 8px !important;
  }

  .main-content {
    padding: 16px !important;
  }

  .sidebar {
    padding: 10px !important;
  }

  .main-content {
    max-width: calc(100% - 173px) !important;
    padding: 10px !important;
    gap: 10px !important;
  }


}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 575px) {
  .table-text h2 {
    font-size: 16px !important;
  }

  .table.tableinfo img {
    width: 23px;
  }

  .tableinnerinfo {
    flex-direction: column;
    margin-top: 4px !important;
    align-items: flex-start !important;
    gap: 2px !important;
  }

  .people img {
    max-width: 17px !important;
  }

  .time h5 {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 479px) {}

@media only screen and (max-width: 349px) {
  ul.sidebar-menu-items {
    height: calc(100vh - 140px) !important;
  }

  ul.sidebar-menu-items li {
    padding: 8px 4px !important;
  }

  .main-content {
    max-width: calc(100% - 160px) !important;
  }

  .table-bg {
    padding: 4px !important;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
  }

  .table-text h2 {
    font-size: 16px !important;
  }

  .table.tableinfo img {
    width: 22px;
    height: 22px;
  }

  .people img {
    max-width: 14px;
  }

  .time {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .table.tableinfo {
    align-items: flex-start;
  }

}
li{
  list-style: none;
}

/* .addremove {
  display: flex;
  border: 1px solid black;
  justify-content: center;
  border-radius: 5px;
} */
.accordion-body ul {
  padding: 0px;
}
.itemmaindetail {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
}

.itemsubdetail {}

.itemsubdetail a {
  text-decoration: none;
  color: var(--Neutral-800, #1F2937);

  /* Label/Small/Semi Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 116.667% */
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 5px;
}

.itemsubdetail span {
  color: var(--Neutral-600, #4B5563);

  /* Label/XSmall/Semibold */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 140% */
}

.itemaddremove {
  margin-left: auto;
  margin-right: 10px;
}

.addremove {
  border-radius: 6px;
  border: 0.75px solid var(--Primary-100, #DCE9E3);
  background: var(--Primary-50, #EAD8E0);
  box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.04), 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.04);
  padding: 6px 9px;
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 5px;
}

.addremove a {
  text-decoration: none;
  color: var(--primary-500-main, #5a0226);
  text-align: center;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 10.5px;
  /* 116.667% */
}

.addremove span {
  color: var(--primary-500-main, #5a0226);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10.5px;
  /* 116.667% */
}

.itemaddremove p {
  color: var(--Neutral-600, #4B5563);

  /* Label/XSmall/Semibold */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 140% */
  text-align: right;
}

.pastorder-item {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  padding: 10px;
}

.accordion-body ul {
  padding: 0px;
  margin-bottom: 0;
}
.itemsubdetail h5 {
  margin-bottom: 0px;
  font-size: 18px;
}

itemaddremove p {
  margin: 0px !important;
}

.itemaddremove p {
  margin-bottom: 0;
}
.Toastify__toast-container {
  z-index: 999999999 !important;
}