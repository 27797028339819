.logo {
    display: flex;
    align-items: center;
    gap: 11px;
}

.logo img {
    background: #FEF2F2;
    padding: 5px;
    border-radius: 6px;
}

.logo h4 {
    margin: 0;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 16px */
}

.header {
    background: #fff;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Neutral-200, #E5E7EB);
    position: fixed;
    width: 100%;
    top: 0;
}

.menu-header {
    position: absolute;
    top: 49px;
    right: 46px;
    width: 210px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1000;
}

.menu-header.open {
    display: block;
    /* Display when the menu is open */
    /* Add other styles */
}

ul.menu-header.open {
    padding: 8px;
}

.menu-header a{
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Neutral-600, #4B5563);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    list-style: none;
    text-decoration: none;
}
button#dropdown-basic {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
}

button#dropdown-basic::after {
    display: none;
}

a.dropdown-item {
    font-size: 17px;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    color: #000;
}