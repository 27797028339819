.main-content {
    max-width: calc(100% - 185px);
    right: 0;
    width: 100%;
    padding: 24px;
    margin-left: auto;
    margin-top: 67px
}

.main-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.table-bg {
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary-500-main, #71917F);
    background: rgba(230, 248, 242, 0.60);
    justify-content: space-between;
}

.table {
    display: flex;
    align-items: center;
    gap: 16px;
}

.tableinnerinfo {
    display: flex;
    align-items: center;
    gap: 6px;
}

.people {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none !important;
}

.table-text h2 {
    color: var(--Neutral-800, #1F2937);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 20px */
    letter-spacing: -0.4px;
    margin-bottom: 6px;
    margin-top: 0;
}

.tableinnerinfo p {
    color: var(--Neutral-900, #374151) !important;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 0;
    border-right: 1px solid #E5E7EB;
    padding-right: 6px !important;
    background: none !important;
}

.people h5 {
    color: var(--Neutral-900, #374151);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 0;
}

.time h5 {
    color: var(--Neutral-800, #1F2937);
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 12px */
    margin-top: 0;
    margin-bottom: 4px;
}

.time h6 {
    color: var(--Neutral-500, #6B7280);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 8px */
    margin: 0;
}

.table-bg.bg-yellow {
    border: 1px solid var(--Warning-200, #FDE68A);
    background: var(--Warning-50, #FFFBEB);
}

.table-bg.bg-red {
    border: 1px solid var(--Destructive-200, #FECACA);
    background: var(--Destructive-50, #FEF2F2);
}

.fade.singleitem.modal.show {
    z-index: 999999;
    background: #F9FAFB;
    display: flex !important;
    right: 0;
    position: fixed;
    height: 100%;
    outline: none;
    width: 100%;
    margin-left: auto;
    max-width: 540px;
    justify-content: center;
}

.tableinnerinfo p {
    padding: 0 !important;
}

.people {
    padding: 0 !important;
}

.table-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.table-info h3 {
    color: #000;
    text-align: center;

    /* Heading/H3/Bold/Desktop */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
    letter-spacing: -0.64px;
    margin-top: 0;
    margin-bottom: 4px;
}

.modaltableinnerinfo p {
    color: var(--Neutral-400, #9CA3AF);
    text-align: center;

    /* Label/Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    border-right: 1px solid #9CA3AF;
    padding-right: 12px;

}

.modalpeople h5 {
    color: var(--Neutral-400, #9CA3AF);
    text-align: center;

    /* Label/Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.modaltableinnerinfo {
    display: flex;
    align-items: center;
    gap: 12px;
}

.modalpeople {
    display: flex;
    align-items: center;
    gap: 12px;
}

.table.tableinfo {
    width: auto;
    margin-bottom: 0;
}


.modal-dialog {
    margin: 0 !important;
}

.modal-content {
    border-radius: 0;
    border: none !important;
    background: none !important;
}

.modal-header {
    border: none !important;
    padding: 0 !important;
    align-items: flex-start !important;
}

.modal-body {
    padding: 0 !important;
}

button.accordion-button.collapsed input {
    width: 18px;
    height: 18px;
}

.accordion-item {
    border: none !important;
    padding: 8px;
}

.accordion-button:not(.collapsed) {
    background: none !important;
    box-shadow: none !important;
}

.accordion-body {
    border-radius: 4px;
    background: var(--Neutral-50, #fff);
    padding: 8px !important;
}

.accordianinfo {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.order-name {
    display: flex;
    align-items: center;
    gap: 4px;
}

.order-name h5 {
    color: var(--Neutral-800, #1F2937);

    /* Label/Small/Semi Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 116.667% */
    margin-bottom: 0;
}

.accordiabinfo-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-prize p {
    color: var(--Neutral-600, #4B5563);

    /* Label/XSmall/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 140% */
    margin-bottom: 0;
}

.order-name img {
    width: 20px;
    height: 20px;
}

button.accordion-button {
    color: var(--Neutral-600, #4B5563);
    text-align: center;

    /* Paragraph/Large/Semibold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
}

button.accordion-button {
    padding: 0;
    margin-bottom: 12px;
}

.countitems {
    border-radius: 6px;
    border: 0.75px solid var(--Primary-100, #DCE9E3);
    background: var(--Primary-50, #EAD8E0);
    box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.04), 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.04);
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4.5px;
}

.countitems p {
    color: var(--primary-500-main, #71917F);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.5px;
    /* 116.667% */
    margin: 0;
}

.accordiabinfo-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.countitems img {
    width: 18px;
    height: 18px;
}

button.accordion-button input {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.accordianinfo-bg {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.accordion {
    --bs-accordion-btn-focus-box-shadow: none !important;
    --bs-accordion-active-bg: none !important;
}

input[type="checkbox"] {
    /* Unchecked state */
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid #688A77;
    /* Change border color for unchecked state */
    border-radius: 3px;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    /* Checked state */
    background-color: #688A77;
    /* Change background color for checked state */
    border-color: #688A77;
    /* Change border color for checked state */
}

input[type="checkbox"]:checked::after {
    content: '\2713';
    color: #fff;
    font-size: 16px;
    text-align: center;
    justify-content: center;
    display: flex;
    width: 16px;
    height: 16px;
    top: 1px;
    position: absolute;
}

.itemtitle.margin {
    margin-top: 30px;
}

.prize {
    /* display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    margin-top: 30px; */
    display: flex;
    align-items: end;
    gap: 24px;
    justify-content: end;
    margin-top: 30px;
    width: auto;
}

.prize-bg {
    border-radius: 8px;
    border: 1px solid var(--Neutral-300, #D1D5DB);
    display: flex;
    height: 100px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.prize-bg h6 {
    color: var(--Neutral-500, #6B7280);
    text-align: justify;

    /* Paragraph/XSmall/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
    margin-bottom: 4px;
}

.prize-bg h4 {
    color: var(--Neutral-900, #111827);

    /* Heading/H5/Semibold/Desktop */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.48px;
    margin-bottom: 0;
}

.prize-bg.bg-green {
    border-radius: 8px;
    border: 1px solid var(--primary-500-main, #71917F);
    background: var(--Primary-50, #E6F8F2);
}

.prize-bg.bg-green h6 {
    font-weight: 400;
    color: var(--primary-500-main, #71917F);
}

.prizeoff {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.prizeoff h4 {
    color: var(--Primary-600, #688A77);
}

.prizeoff p {
    color: var(--Primary-400, #829E8E);
    text-align: justify;

    /* Label/XSmall/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    margin: 0;
}

.prize-bg.bg-green p {
    color: var(--Primary-400, #829E8E);
    text-align: justify;

    /* Label/XSmall/Medium */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    margin: 0;
}

.modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
}

.settle a {
    display: flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-500-main, #71917F);
    background: var(--primary-500-main, #71917F);

    /* Drop shadow/Small */
    box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
    color: var(--Generic-White, #FFF);
    text-align: center;

    /* Label/Large/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
    text-decoration: none;
}

.settle {
    margin-top: 30px;
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
    background: rgba(0, 0, 0, 0.20);
    /* Background color with opacity */
    backdrop-filter: blur(10px);
    /* Blur effect */
}

.modal-backdrop.show {
    /* Override the backdrop opacity */
    --bs-backdrop-opacity: 1;
    /* Ensure the opacity doesn't interfere with custom background */
    background: rgba(0, 0, 0, 0.20);
    /* Background color with opacity */
    backdrop-filter: blur(10px);
    /* Blur effect */
    opacity: 1;
    /* Set the overall opacity for the element */
}

/* Custom modal style for right-to-left animation */
.modal.right-to-left .modal-dialog {
    margin: auto;
    width: 30%;
    /* Adjust width as per your design */
    height: 100%;
    left: 100%;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-in-out;
}

.modal.right-to-left.show .modal-dialog {
    transform: translate3d(-100%, 0, 0);
}

.modal-dialog {
    max-width: 540px !important;
    height: 100% !important;
    padding: 16px !important;
}

.fade.singleitem.right-to-left.modal.show {
    padding-left: 0 !important;
}

.additem {
    margin-bottom: 20px;
}
.combo-page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* border: px solid gray; */
    padding: 0 10px;
    background: #5a022614;
}
.combo-info{
    display: flex;
    flex-direction: column;
    /* gap: 25px; */
}
.orderbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.orderbtn .order-complete{
    margin-top: 30px;
    width: 48%;
}
.orderbtn a {
    display: flex ;
    padding: 14px 20px ;
    justify-content: center ;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-500-main, #71917F);
    background: var(--primary-500-main, #71917F);
    /*Dropshadow/Small*/box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
    color: var(--Generic-White, #FFF);
    text-align: center;
    /*Label/Large/Medium*/font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /*112.5%*/text-decoration: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.accordiabsubIteminfo {
    display: flex;
    justify-content: space-between;
    padding: 13px 0px;
    margin-bottom: 10px;
}
.horizontal-line {
    border-bottom: 1px solid #DCE9E3;
    margin-bottom: 10px;
}


.fade.custom-modal.modal.show {
    z-index: 999999;
}

.fade.modal-backdrop.show {
    z-index: 999999;
}

.complete-order-list {
    max-height: 500px;
    overflow: auto;
}